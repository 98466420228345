import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import {
  ContHablemos,
  ContTituloHablemos,
  GlobalStyleHablemos,
  TextoDeTheOcean,
  TextoHablemosDe,
  TituloHablemos,
} from './styles';
import ContactoComp from '../Contacto';

export const HablemosComp = () => {

  return (
    <div className="cont-page page-hablemos">
      <Container className={'hablemos'} isFluid={false}>
        <GlobalStyleHablemos/>
        <ContTituloHablemos>
          <TituloHablemos><span>Hablemos</span></TituloHablemos>
        </ContTituloHablemos>
        <Columns className="columns-responsive full-height">
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <ContHablemos>
              <TextoHablemosDe>De The Ocean Group</TextoHablemosDe>
              <TextoDeTheOcean>Grupo de Desarrollo Inmobiliario, creamos, estructuramos y administramos proyectos como;
                Fraccionamientos urbanizados, Complejos de vivienda horizontal <i>(Casas)</i> y vivienda
                vertical <i>(Departamentos/Condominios)</i> y Mixtos. Somos una Empresa con más de 25 años de
                experiencia en el Sector Inmobiliario y más de 20 proyectos exitosos realizados.</TextoDeTheOcean>
            </ContHablemos>
          </Column>
        </Columns>
      </Container>

      <ContactoComp/>

    </div>
  );
};

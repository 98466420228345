import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyleHablemos = createGlobalStyle`
  .page-hablemos{
    height: auto !important;
    padding-bottom: 50px;
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  #hablemos {
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
`
export const ContTituloHablemos = styled.div`
  position: relative;

  margin-top: 50px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const TituloHablemos = styled.div`
  font-family: ${GlobalFonts.fontSecondary};
  
  color: ${GlobalColors.colorPrimary};
  
  & > span{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 50px;
    line-height: 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 70px;
    line-height: 80px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 80px;
    line-height: 90px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 100px;
    line-height: 110px;
  }
  @media(min-width: 1408px){
    font-size: 100px;
    line-height: 110px;
  }
`
export const ContHablemos = styled.div`
  position: relative;
  margin: 0 auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 80%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 60%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 60%;
  }
  @media(min-width: 1920px){
    width: 60%;
  }
`
export const TextoHablemosDe = styled.div`
  position: relative;
  margin-bottom: 15px;
  font: 16px '${GlobalFonts.fontRegular}';
  color: ${GlobalColors.colorLinkHover};
`
export const TextoDeTheOcean = styled.div`
  position: relative;
  margin-bottom: 15px;
  font: 16px '${GlobalFonts.fontRegular}';
  color: ${GlobalColors.colorMenuPrimary};
`
